<template>
<div class="container-fluid">
	<div class="row">
	<div class="col-lg-12">
		<div class="d-flex flex-wrap align-items-center justify-content-center mb-4">
		<div class="text-center">
			<h4 class="mb-3">{{ title }}</h4>
			<p class="mb-0">{{ desc1 }}<br> {{ desc2 }}<br>{{ desc3 }}</p>
			<p v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></p>
			<b-modal id="modal-3" size="sm" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
			<p>1. 모든 크롬 탭을 닫고 새탭을 열어 쿠팡윙에 로그인하고 새탭을 열어 쿠플러스에 다시 로그인 후에 다시 시도하기</p>
			<p>2. http://www.couplus.co.kr 링크로 다시 접속해서 시도하기</p>
			<p>3. 크롬 브라우저 사용하기</p>
			<p>4. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
			<p>5. 크롬 브라우저 삭제 후 재설치</p>
			</b-modal>
		</div>
		</div>
	</div>
	<div class="col-lg-12">
		<div class="d-flex justify-content-center" style="height:150%">
		<input ref="cursor" type="text" class="ml-2 form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="search">
		</div>
	</div>
	<div class="col-lg-12 mt-4 mb-5">
		<div class="d-flex justify-content-center align-items-center">
			<button type="button" class="btn btn-primary mr-2" @click="search">수동광고키워드 생성</button>
			<button v-if="isClassifying" type="button" class="btn btn-warning mr-2" @click="toggleClassification">
			{{ isPaused ? '분류 계속' : '분류 일시 중지' }}
			</button>
			<button v-if="isPaused" type="button" class="btn btn-danger" @click="stopClassification">분류 중단</button>
		</div>
	</div>

	<div class="col-lg-12 d-flex justify-content-center text-center mt-5" v-if="showSpinner">
		<div class="custom-control custom-control-inline d-flex justify-content-center">
		<div class="row">
			<div class="col-12">
			<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
			<span>{{ loadingText }}... {{ loadingRate }}</span>
			</div>
		</div>
		</div>
	</div>
	
	<div class="col-lg-12 d-flex justify-content-center" v-if="showList">
		<div class="category-container">
		<div class="categories-scroll" ref="categoriesScroll">
			<div class="categories-wrapper">
				<card v-for="(category, index) in categories" :key="index" class="category-card">
					<div class="category-header">
					<h6 class="category-name" :ref="'categoryName_' + index">
						{{ category.name }} ({{ category.keywords.length }})
					</h6>
					<v-btn icon small @click="downloadExcel(category)">
						<v-icon>mdi-download</v-icon>
					</v-btn>
					</div>
					<div class="category-keywords" :ref="'categoryKeywords_' + category.name">
					<transition-group name="keyword-move">
						<v-chip
						v-for="(keyword,idx) in category.keywords"
						:key="idx"
						class="ma-1 ml-1 keyword-chip bordered-chip"
						close
						@click:close="removeKeywordFromCategory(category, keyword)"
						:style="{ maxWidth: '100%' }"
						>
						<!-- <span class="keyword-text">{{ keyword.text }} ({{ keyword.rate }}%, {{ keyword.click }})</span> -->
						<span class="keyword-text">{{ keyword.text }} ({{ keyword.rate }}%)</span>
						</v-chip>
					</transition-group>
					</div>
				</card>
			</div>
		</div>
		<button class="scroll-button left" @click="scrollCategories('left')">&lt;</button>
		<button class="scroll-button right" @click="scrollCategories('right')">&gt;</button>
		</div>
	</div>

	<div class="col-lg-12 d-flex justify-content-center text-center mt-4" v-if="showList">
		<div class="w-100 h-100">
		<h5>미분류 키워드 (total : {{ allKeywords.length }}개)</h5>
		<div class="d-flex justify-content-center">
			<card class="p-3 h-100 w-75">
			<v-row>
				<v-col cols="12" class="d-flex flex-wrap uncategorized-keywords" ref="uncategorizedKeywords">
				<transition-group name="keyword-move">
					<v-chip
					v-for="(keyword,index) in allKeywords"
					:key="index"
					class="ma-1 ml-1 keyword-chip bordered-chip"
					:style="{ maxWidth: '100%' }"
					>
					<span class="keyword-text">{{ keyword }}</span>
					</v-chip>
				</transition-group>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
				<b-button v-clipboard:copy="fullTag" variant="outline-success">키워드복사</b-button>
				</v-col>
			</v-row>
			</card>
		</div>
		</div>
	</div>
	</div>
</div>
</template>

<script>
import * as XLSX from 'xlsx';

export default {
name: 'AdKeywordGenerator',
data() {
	return {
		title: "수동 키워드 광고 최적화",
		desc1: "수동 키워드 광고에 최적화된 키워드를 자동으로 수집합니다.",
		desc2: "키워드가 부족한 경우 자동으로 키워드 확장을 실행합니다.",
		desc3: '상품의 카테고리가 맞는 1차 타겟키워드를 입력해 주세요',
		targetKeyword: '',
		showSpinner: false,
		showList: false,
		loadingText: '',
		loadingRate: '',
		allKeywords: [],
		displayedKeywords: [],
		fullTag: "",
		maxDisplayKeywords: 500,
		categories: [],
		categorizationComplete: false,
		isClassifying: false,
		isPaused: false,
		currentKeyword: null,
	}
},
computed: {
	displayedCount() {
	return Math.min(this.allKeywords.length, this.maxDisplayKeywords);
	},
},
watch: {
    allKeywords: {
      handler() {
        this.updateDisplayedKeywords();
        this.makeTag();
      },
      deep: true,
    },
    categories: {
      handler() {
        this.$nextTick(this.adjustCategoryNameFontSize);
      },
      deep: true
    }
  },
beforeDestroy() {
	window.removeEventListener('message', this.messageEventHandler);
},
mounted() {
	this.messageEventHandler = async(event) => {
		console.log(event.data)
		if (event.data.res && event.data.res === 'adkeywords') {
			this.allKeywords = event.data.relatedkeywords;
			this.updateDisplayedKeywords();
			this.makeTag();
			this.showList = true;
			this.showSpinner = false;
			await this.$nextTick();
			this.startCategorization();
		}
		if (event.data.res && event.data.res === 'relkeywordsrate') {
			this.loadingRate = '(' + event.data.n + '차)' + (event.data.rate * 100).toFixed(0) + '%';
		}
		if (event.data.res && event.data.res === 'getKeywordCategory') {
			if (this.isClassifying && !this.isPaused) {
				this.processCategory(event.data.keyword, event.data.category, event.data.click, event.data.rate);
			}
		}
    }
    window.addEventListener('message', this.messageEventHandler);
	this.$nextTick(this.adjustCategoryNameFontSize);
},
methods: {
	startCategorization() {
      this.isClassifying = true;
      this.isPaused = false;
      this.categorizeNextKeyword();
    },
	categorizeNextKeyword() {
      if (this.allKeywords.length > 0 && this.isClassifying && !this.isPaused) {
        this.currentKeyword = this.allKeywords[0];
        this.getKeywordCategory(this.currentKeyword);
      } else if (this.allKeywords.length === 0) {
        this.finishCategorization();
      }
    },
	async categorizeKeywords() {
		this.isClassifying = true;
		this.isPaused = false;
		
		for (const keyword of this.allKeywords) {
			if (this.isPaused) {
			await new Promise(resolve => {
				this.resumeClassification = resolve;
			});
			}
			
			const category = await this.getKeywordCategory(keyword);
			let categoryObj = this.categories.find(c => c.name === category);
			if (!categoryObj) {
				categoryObj = { name: category, keywords: [] };
				this.categories.push(categoryObj);
			}
			categoryObj.keywords.push(keyword);
			this.allKeywords = this.allKeywords.filter(k => k !== keyword);
			await this.$nextTick();
			this.scrollCategoryToBottom(categoryObj);
		}
		
		this.categorizationComplete = true;
		this.isClassifying = false;
	},
	
	getKeywordCategory(keyword) {
      window.postMessage({ 
        greeting: "getKeywordCategory", 
        keyword: keyword
      }, "*");
    },
	processCategory(keyword, category, click, rate) {
      if (keyword !== this.currentKeyword) return;

      let categoryObj = this.categories.find(c => c.name === category);
      if (!categoryObj) {
        categoryObj = { name: category, keywords: [] };
        this.categories.push(categoryObj);
      }
      
      const keywordObj = {
        text: keyword,
        click: click,
        rate: rate
      };

      categoryObj.keywords.push(keywordObj);
    //   categoryObj.keywords.sort((a, b) => b.click - a.click);  // 클릭수로 정렬

      this.allKeywords = this.allKeywords.filter(k => k !== keyword);
      this.$nextTick(() => {
        this.adjustCategoryNameFontSize();
        this.scrollCategoryToBottom(categoryObj);
        this.categorizeNextKeyword();
      });
    },
	adjustCategoryNameFontSize() {
      this.$nextTick(() => {
        this.categories.forEach((category, index) => {
          const element = this.$refs[`categoryName_${index}`][0];
          if (element) {
            element.style.fontSize = ''; // 기존 font-size 스타일 제거
            element.style.whiteSpace = 'normal'; // 줄 바꿈 허용
            element.style.wordBreak = 'break-word'; // 긴 단어 줄 바꿈
          }
        });
      });
    },
	scrollCategoryToBottom(category) {
	this.$nextTick(() => {
		const categoryEl = this.$refs[`categoryKeywords_${category.name}`][0];
		if (categoryEl) {
		const isScrolledToBottom = categoryEl.scrollHeight - categoryEl.clientHeight <= categoryEl.scrollTop + 1;
		if (isScrolledToBottom) {
			categoryEl.scrollTop = categoryEl.scrollHeight;
		}
		}
	});
	},
	removeKeywordFromCategory(category, keyword) {
      category.keywords = category.keywords.filter(k => k.text !== keyword.text);
      this.allKeywords.unshift(keyword.text);
      this.$nextTick(this.adjustCategoryNameFontSize);
    },
	toggleClassification() {
      this.isPaused = !this.isPaused;
      if (!this.isPaused) {
        this.categorizeNextKeyword();
      }
    },

    stopClassification() {
      this.isClassifying = false;
      this.isPaused = false;
      this.currentKeyword = null;
    },

    finishCategorization() {
      this.categorizationComplete = true;
      this.isClassifying = false;
      this.currentKeyword = null;
    },
	
	search() {
		if (!this.targetKeyword) {
			alert('상품과 결이 같은 구체적인 타겟 키워드를 입력해 주세요. 좋은예시 : "등산백팩", 나쁜예시 : "백팩"')
			return
		}
		window.postMessage({ greeting: "getadkeyword", data: [this.targetKeyword], cnt:2 }, "*");
		this.loadingText = '수동 타겟 키워드 수집 중';
		this.loadingRate = '';
		this.showSpinner = true;
		this.showList = false;
		this.categories = []; // 카테고리 초기화
		this.categorizationComplete = false;
	},
	makeTag() {
		this.fullTag = this.allKeywords.join(",").trim();
	},
	updateDisplayedKeywords() {
		this.displayedKeywords = this.allKeywords.slice(0, this.maxDisplayKeywords);
	},
	downloadExcel(category) {
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.aoa_to_sheet([
			["키워드", "입찰가"],
			...category.keywords.map(keyword => [keyword, ""])
		]);
		
		XLSX.utils.book_append_sheet(workbook, worksheet, '수동키워드');
		
		const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		this.saveExcelFile(excelBuffer, `수동광고최적화키워드.xlsx`);
	},
	saveExcelFile(buffer, fileName) {
		const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(data);
		link.download = fileName;
		link.click();
	},
	scrollCategories(direction) {
		const container = this.$refs.categoriesScroll;
		const scrollAmount = 300; // 조절 가능한 스크롤 양
		if (direction === 'left') {
			container.scrollLeft -= scrollAmount;
		} else {
			container.scrollLeft += scrollAmount;
		}
	},
}
}
</script>

<style scoped>
.modal-dialog {
max-width: 90%;
position: absolute;
margin: auto;
top: 0;
bottom: 0;
left: 0;
right: 0;
}

.category-name {
  flex-grow: 1;
  margin-right: 10px;
  word-break: break-word;
  line-height: 1.2;
  font-size: 1em; /* 기본 폰트 크기 */
}

.keyword-chip {
  max-width: 100%;
  white-space: normal;
  height: auto !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

.keyword-text {
  word-break: break-all;
  font-size: 0.85em;  /* 글자 크기를 약간 줄임 */
}

.bordered-chip {
border: 1px solid rgba(0, 0, 0, 0.12) !important;
}


.category-container {
width: 100%;
position: relative;
padding: 0 30px;
}

.categories-scroll {
overflow-x: auto;
white-space: nowrap;
scrollbar-width: none; /* Firefox */
-ms-overflow-style: none; /* IE and Edge */
scroll-behavior: smooth;
}

.categories-scroll::-webkit-scrollbar {
display: none; /* Chrome, Safari, Opera */
}

.categories-wrapper {
display: inline-flex;
padding: 10px 0;
}

.category-card {
  width: 300px; /* 고정 너비 */
  flex: 0 0 auto;
  margin-right: 20px;
  white-space: normal;
  display: flex;
  flex-direction: column;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px 4px 0 0;
}



.category-keywords {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.category-keywords, .uncategorized-keywords {
flex-grow: 1;
overflow-y: auto;
padding-right: 5px;
max-height: 350px; /* Adjust as needed */
}

.keyword-move-enter-active, .keyword-move-leave-active {
transition: all 0.5s;
}

.keyword-move-enter, .keyword-move-leave-to {
opacity: 0;
transform: translateY(30px);
}

.keyword-move-leave-active {
position: absolute;
}

.scroll-button {
position: absolute;
top: 50%;
transform: translateY(-50%);
background: rgba(0, 0, 0, 0.5);
color: white;
border: none;
padding: 10px;
cursor: pointer;
z-index: 1;
}

.scroll-button.left {
left: 0;
}

.scroll-button.right {
right: 0;
}

/* 스크롤바 스타일링 */
.category-keywords::-webkit-scrollbar, .uncategorized-keywords::-webkit-scrollbar {
width: 5px;
}

.category-keywords::-webkit-scrollbar-thumb, .uncategorized-keywords::-webkit-scrollbar-thumb {
background: #888;
border-radius: 5px;
}

.category-keywords::-webkit-scrollbar-thumb:hover, .uncategorized-keywords::-webkit-scrollbar-thumb:hover {
background: #555;
}

.uncategorized-keywords {
max-height: 400px;
overflow-y: auto;
padding-right: 5px;
}

.btn-warning {
background-color: #ffc107;
border-color: #ffc107;
color: #212529;
}

.btn-danger {
background-color: #dc3545;
border-color: #dc3545;
color: #fff;
}
</style>